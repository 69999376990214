import React from 'react';
import { useContent, useRoute } from '../core/hooks';
import WidgetNode from './widget-node';
export default function Fixed({ page }: { page?: string }) {
  const route = useRoute();
  const { fixed } = useContent();
  if ((!page && !route) || !fixed) return null;
  const { children = [], visibility = {} } = fixed;

  return (
    <React.Fragment>
      {children
        .filter(id => !(id in visibility) || visibility[id][page || route.pageKey])
        .map(id => (
          <WidgetNode key={id} id={id} fixed />
        ))}
    </React.Fragment>
  );
}
