import React, { forwardRef } from 'react';
import { IShape, IconProps } from 'vev';
import { isString } from '../../utils';
import { useIcon } from '../../core/hooks';

const DEFAULT_SHAPE: IShape = [100, 100, ['M50 0 L100 100  L0 100 Z']];

export default forwardRef(({ stretch, d, ...rest }: IconProps, ref)  => {
  const shape: IShape | string | undefined = useIcon(d || 'default');

  if (isString(shape)) return <img data-icon src={shape} ref={ref as any} {...rest} />;
  //   if (/(http(s)?:)?\/\//.test(shape)) {
  //     // If its url then return image
  //     return <img data-icon src={shape} className={className} style={style} />;
  //   } else {
  //     shape = DEFAULT_SHAPE;
  //   }

  const [width, height, ...paths]: IShape = shape || DEFAULT_SHAPE;
  return (
    <svg
      ref={ref as any}
      data-icon
      {...rest}
      preserveAspectRatio={stretch ? 'none' : 'xMidYMid'}
      viewBox={`0 0 ${width} ${height}`}>
      {isString(paths) ? (
        <path d={paths} />
      ) : (
        paths.map((path:string, index) => <path key={index} d={path} />)
      )}
    </svg>
  );
});
