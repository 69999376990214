import { isUndefined } from '../core';

declare const dataLayer: any;
declare const ga: any;

type TrackingLayer = (
  event: string,
  category?: string,
  action?: string,
  label?: string,
  value?: any
) => void;

isUndefined;

class TrackingManager {
  layers: TrackingLayer[] = [];

  add(layer: TrackingLayer) {
    this.layers.push(layer);
  }

  send(event: string, category?: string, action?: string, label?: string, value?: any): void {
    for (let layer of this.layers) layer.apply(layer, arguments as any);
  }
}

const manager = new TrackingManager();
manager.add(
  (event: string, category?: string, action?: string, label?: string, value?: any): void => {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        event: event,
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value
      });
    }
  }
);

manager.add(
  (
    event: string,
    eventCategory?: string,
    eventAction?: string,
    eventLabel?: string,
    eventValue?: any
  ): void => {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', {
        eventCategory,
        eventAction,
        eventLabel,
        eventValue,
        transport: 'beacon'
      });
    }
  }
);

export default manager;
