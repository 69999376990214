export function isString(val: any): val is string {
  return typeof val === 'string';
}

export function isUndefined(val: any): val is undefined {
  return val === undefined;
}

export function isFunction(val: any): val is Function {
  return typeof val === 'function';
}

export function isNumber(val: any): val is number {
  return typeof val === 'number';
}
